<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="salaryPaymentForm" @submit.prevent="saveSalaryPayment" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                                    <v-toolbar-title class="subtitle-2">Salary Payment</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="2"></v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Employee</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="name"
                                                        item-value="id"
                                                        @focus="$store.dispatch('employee/getEmployees')"
                                                        :loading="$store.getters['employee/loading']"
                                                        :items="$store.getters['employee/employees']"
                                                        v-model="selectedEmployee"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="selectedEmployee != null" no-gutters class="mb-1">
                                                <v-col cols="4">Salary</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="selectedEmployee.salary_range"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Month</v-col>
                                                <v-col cols="7" class="pr-1">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="name"
                                                        item-value="id"
                                                        @focus="$store.dispatch('month/getMonth')"
                                                        :loading="$store.getters['month/loading']"
                                                        :items="$store.getters['month/months']"
                                                        v-model="selectedMonth"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <month :icon="true"></month>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="salaryPaymentEditId == null" no-gutters class="mb-1">
                                                <v-col cols="4">Payable Amount</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['employeeSalaryPayment/payableAmount']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Date</v-col>
                                                <v-col cols="8">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="payment_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="payment_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Payment Amount</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="payment_amount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4">Deduction </v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="deduction_amount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4"></v-col>
                                                <v-col cols="8">
                                                    <v-row dense>
                                                            <v-col cols="6">
                                                                <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="salaryPaymentHeaders"
                    :loading="$store.getters['employeeSalaryPayment/loading']"
                    :items="$store.getters['employeeSalaryPayment/salaryPayments']"
                    :search="searchSalaryPayments"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Salary Payment List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Salary Payments"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchSalaryPayments"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editSalaryPayment(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteSalaryPayment"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import Month from '@/components/Month'

export default {
    name: 'SalaryPayment',
    components: {
        DeleteConfirm,
        Month,
    },
    data: ()=> ({
        menu: false,
        searchSalaryPayments: '',
        salaryPaymentHeaders: [
            { text: 'Date', value: 'payment_date' },
            { text: 'Employee Id', value: 'employee.code' },
            { text: 'Employee Name', value: 'employee.name' },
            { text: 'Month', value: 'month.name' },
            { text: 'Payment Amount', value: 'payment_amount' },
            { text: 'Deducted Amount ', value: 'deduction_amount' },
            { text: 'Action ', value: 'action' },
        ],

        payment_date: new Date().toISOString().substr(0, 10),
        payment_amount: 0,
        deduction_amount: 0,
        selectedEmployee: null,
        selectedMonth: null,
        salaryPaymentEditId: null,
        salaryPaymentDeleteId: null,
        waitingForSave: false,
        userType: ''
    }),
    watch: {
        selectedEmployee: function() {
            this.getPayableAmount()
        },
        selectedMonth: function() {
            this.getPayableAmount()
        },
    },
    created() {
        this.$store.dispatch('employeeSalaryPayment/getSalaryPayments')
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },
    methods: {
        async saveSalaryPayment() {
            let valid = this.$refs.salaryPaymentForm.validate()
            if (!valid) return

            let data = {
                employee_id: this.selectedEmployee.id,
                month_id: this.selectedMonth.id,
                payment_date: this.payment_date,
                payment_amount: this.payment_amount,
                deduction_amount: this.deduction_amount,
            }

            this.waitingForSave = true

            if (this.salaryPaymentEditId == null) {
                let res = await this.$store.dispatch('employeeSalaryPayment/addEmployeeSalaryPayment', data)
                if (res) this.resetForm()
            } else {
                data.id = this.salaryPaymentEditId
                let res = await this.$store.dispatch('employeeSalaryPayment/updateEmployeeSalaryPayment', data)
                if (res) this.resetForm()
            }

            this.waitingForSave = false
        },

        editSalaryPayment(salaryPayment) {
            this.selectedEmployee = salaryPayment.employee
            this.selectedMonth = salaryPayment.month
            this.payment_date = salaryPayment.payment_date
            this.payment_amount = salaryPayment.payment_amount
            this.deduction_amount = salaryPayment.deduction_amount
            this.salaryPaymentEditId = salaryPayment.id
        },

        openDeleteDialog(id) {
            this.salaryPaymentDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteSalaryPayment() {
            this.$store.dispatch('employeeSalaryPayment/deleteEmployeeSalaryPayment', {id: this.salaryPaymentDeleteId})
            this.salaryPaymentDeleteId = null
            this.$refs.deleteDialog.dialog = false
        },

        getPayableAmount() {
            if (this.selectedEmployee != null && this.selectedMonth != null) {
                this.$store.dispatch('employeeSalaryPayment/getPayableAmount', {
                    employee_id: this.selectedEmployee.id, 
                    month_id: this.selectedMonth.id,
                })
            }
        },

        resetForm() {
            this.selectedEmployee = this.selectedMonth = this.salaryPaymentEditId = null
            this.payment_amount = this.deduction_amount = 0
            this.payment_date = new Date().toISOString().substr(0, 10)
            this.$refs.salaryPaymentForm.resetValidation()
            this.$store.commit('employeeSalaryPayment/setPayableAmount', 0)
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px;
    }
    .v-file-input .v-text-field__slot {
        min-height: 27px !important;
    }
    .e-image {
        .v-input__prepend-inner {
            margin-top: 0 !important;
        }
    }
</style>